import React, { useEffect, useRef, useState } from "react";

// Next
import { usePathname } from "next/navigation";
import { useLocale, useTranslations } from "next-intl";
import Link from "next/link";

// React Icons
import { IoMenu } from "react-icons/io5";
import { FaChevronDown } from "react-icons/fa6";
import { MdHelpOutline } from "react-icons/md";

// Components
import { Button } from "@/components/common/Button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/common/Collapsible";
import Logo from "../../common/Logo";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../common/Tooltip";
import HelpModal from "@/containers/Modals/HelpModal";

// Utils
import { getRoleAccessbility } from "@/utils/functions/getRoleAccessbility";
import { getFQCN } from "@/utils/functions/fqcn";
import { useAppSelector } from "@/utils/hooks/store";
import { cn } from "@/utils/functions/cn";

// Site data
import { SidebarMenus } from "@/sitedata/SidebarMenu";

const fqcn_bui = {
  Bundle: "sidebarMenuBundle",
  Unit: "menu",
  Interface: "Listing",
};

type SidebarMenuProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const SidebarMenu: React.FC<SidebarMenuProps> = ({ isOpen, setIsOpen }) => {
  const [helpModal, setHelpModal] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState<string | null>(null);
  const submenuRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const locale = useLocale();
  const t = useTranslations("SidebarMenu");
  const pathname = usePathname();

  const { user } = useAppSelector((state) => state.auth);

  const generateLink = (
    link: string,
    title: string,
    icon: React.ReactNode,
    subMenu?: boolean
  ) => {
    return (
      <Link
        id={getFQCN(
          fqcn_bui,
          "menu",
          `${title}${subMenu ? "-submenu" : ""}-link`
        )}
        href={link?.includes("https") ? link : `/${locale}${link}`}
        target={link?.includes("https") ? "_blank" : "_self"}
      >
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger
              className={`flex w-full gap-4 p-3 rounded cursor-pointer transition-all ease-in-out duration-300 text-white ${
                pathname.includes(`/${locale}${link}`)
                  ? subMenu
                    ? "text-phpc-blue-dark dark:text-phpc-blue"
                    : "bg-phpc-blue-dark dark:bg-phpc-blue"
                  : `${
                      subMenu
                        ? "hover:text-gray-200"
                        : "hover:text-phpc-blue-dark"
                    } dark:hover:text-phpc-blue`
              }`}
            >
              <span className="flex items-center justify-center h-6">
                {icon}
              </span>
              <CollapsibleContent>
                <p className="w-full whitespace-nowrap overflow-hidden overflow-ellipsis">
                  {t(title)}
                </p>
              </CollapsibleContent>
            </TooltipTrigger>
            <TooltipContent>
              <p>{t(title)}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </Link>
    );
  };

  return (
    <>
      <Collapsible
        open={isOpen}
        onOpenChange={setIsOpen}
        className={`bg-phpc-blue dark:bg-phpc-blue-dark transition-transform duration-500 fixed md:relative min-h-screen z-[60] flex flex-col ${
          !isOpen
            ? "translate-x-[-100%] md:translate-x-0 shadow-xl"
            : "translate-x-0"
        }`}
      >
        <div className="flex flex-col flex-grow print:hidden h-screen relative">
          <article className="sticky top-0 p-2 flex flex-col h-screen overflow-y-auto">
            <div className="flex mb-3">
              <CollapsibleContent>
                <Logo
                  fqcn_bui={{
                    Bundle: "menuBundle",
                    Unit: "logo",
                    Interface: "menu",
                  }}
                />
              </CollapsibleContent>
              <CollapsibleTrigger asChild>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        id={getFQCN(fqcn_bui, "menu", "collapsible-button")}
                        className="w-full m-auto mt-4 p-0 bg-transparent hover:bg-transparent"
                        onClick={() => {
                          setIsOpen(!isOpen);
                          localStorage.setItem(
                            "menuState",
                            JSON.stringify(!isOpen)
                          );
                        }}
                      >
                        <IoMenu
                          id={getFQCN(fqcn_bui, "menu", "collapsible-icon")}
                          className="w-7 h-7 text-white"
                        />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>{isOpen ? t("Close") : t("Open")}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </CollapsibleTrigger>
            </div>

            {SidebarMenus?.map((item, _id) => (
              <div key={_id}>
                <div key={_id} className="border-b border-gray-300/30">
                  {generateLink(item.link, item.title, item.icon, false)}
                </div>
              </div>
            ))}

            <div className="mt-auto mx-auto mb-10">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger
                    className="flex items-center w-full gap-4 p-3 rounded cursor-pointer transition-all ease-in-out duration-300 text-white hover:text-phpc-blue-dark dark:hover:text-phpc-blue"
                    onClick={() => setHelpModal(true)}
                  >
                    <span className="flex items-center justify-center">
                      <MdHelpOutline className="h-5 w-5 mx-auto" />
                    </span>
                    <CollapsibleContent>
                      <p className="w-full whitespace-nowrap overflow-hidden overflow-ellipsis">
                        {t("Help")}
                      </p>
                    </CollapsibleContent>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{t("Press Alt + ?")}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </article>
        </div>
      </Collapsible>

      <div
        className={`fixed inset-0 z-[59] bg-black/50 transition-opacity duration-500 md:hidden ${
          isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        onClick={() => setIsOpen(false)}
      ></div>

      {helpModal && (
        <HelpModal isOpen={helpModal} onClose={() => setHelpModal(false)} />
      )}
    </>
  );
};

export default SidebarMenu;
